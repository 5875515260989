import React from 'react'
import {Link} from 'gatsby'
import logo from "../../assets/images/logo.png"
import footerMap from "../../assets/images/footer-map.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img src={logo} alt="logo" />
                            </a>
                            <p>Noi transformăm orice provocare de business într-o poveste de succes prin soluții IT și software personalizat.</p>

                            <ul className="social-link">
                                <li>
                                    <Link to="https://facebook.com/verticaldigitalca/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-facebook'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://twitter.com/VerticalDigitaI" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-twitter'></i>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="https://www.linkedin.com/company/vertical-digital-canada" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-linkedin'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Descoperă</h3>
                            
                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/">
                                        Acasă
                                    </Link>
                                </li>
                                    <li>
                                    <Link to="/despre-noi/">
                                        Despre noi
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/studii-de-caz/">
                                        Studii de caz
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/locatii/">
                                        Locații
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact/">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Resurse</h3>

                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/echipa/">
                                        Echipa noastră
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/servicii/">
                                        Serviciile noastre
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog/">
                                        Blog
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/intrebari-frecvente/">
                                        Întrebări frecvente
                                    </Link>
                                </li>
                                { /*<li>
                                    <Link to="/cursuri/">
                                        Cursuri
                                    </Link>
                                </li>*/ }
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Adresă</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bx-map'></i> 
                                    Strada Mesteșugarilor nr. 51B etaj 2, Oradea, Bihor, <br /> România
                                </li>
                                <li>
                                    <i className='bx bx-phone-call'></i>
                                    <a href="tel:+40734580793">+40 (734) 580 793</a>
                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <a href="mailto:info@verticaldigital.ro">info@verticaldigital.ro</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright @{currentYear} <strong>Vertical Digital</strong> Toate drepturile rezervare <a target="_blank" href="https://verticaldigital.ro/" rel="noreferrer">Vertical Digital SRL</a></p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <ul>
                                <li>
                                    <Link to="/politica-de-confidentialitate/">
                                        Politica de confidențialitate
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;